$(document).ready(function () {

    $('.owl-social-section').owlCarousel({
        loop: true,
        margin: 1,
        dots: true,
        nav: true,
        navText: ["<img src='img/arrow-left.png'>", "<img src='img/arrow-right.png'>"],
        onInitialized  : brandSliderClasses,
        onTranslated : brandSliderClasses,
        responsive:{
            0:{
                items:2,
            },
            768:{
                items:3,
                center:true
            }
        }
    });

    function brandSliderClasses() {
        $('.owl-social-section').each(function() {
            $(this).find('.owl-item').removeClass('firstActiveItem');
            $(this).find('.owl-item.active').each(function(index) {
                if (index === 0) {
                    $(this).addClass('firstActiveItem');
                }
            })
        })
    }

});